@font-face {
    font-family: ProximaNova;
    font-weight: 500;
    src: local(AvenirNext),
            url('fonts/proxima-nova-regular.ttf') format('truetype');
  }
  
@font-face {
  font-family: ProximaNova;
  font-weight: bold;
  src: local(AvenirNext),
          url('fonts/proxima-nova-bold.ttf') format('truetype');
}
  
@font-face {
    font-family: ProximaNova;
    font-weight: 600;
    src: local(AvenirNext),
    url('fonts/proxima-nova-semibold.ttf') format('truetype');

}

@font-face {
  font-family: ProximaNova;
  font-style: italic;
  src: local(AvenirNext),
  url('fonts/semi_bold_it.ttf') format('truetype');
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.footer-link, .footer-link:hover {
  font-family: ProximaNova;
  font-size: 20px;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;

}