.loader-container {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 99999;

    >img {
        position: absolute;
        top: 32%;
        left: 36%;
    }
}